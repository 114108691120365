<template>
    <section>
        
        <div class="content-wrapper gold-circle">
            <div class="row">
                <img id="spinning-ball" src="@/assets/images/import/boule_doree.png" alt="">
                <div class="col-md-12 d-flex justify-content-end text-right position-absolute">
                    <div>
                        <h1 data-aos="fade-left" data-aos-duration="1000">Prestations</h1>
                        <hr class="gold-yellow">
                    </div>
                </div>
            </div>

            <!-- liste des services -->
            <div class="row" style="margin-top: -15rem">
                <div v-if="!categorie" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div v-else class="col-md-4 col-sm-12 d-flex justify-content-center p-1 img-container black-hover " v-b-modal.reservation @click="setCategorie(item)"
                v-for="item in categorie"
                :key="item.id"
                data-aos="zoom-in"
                data-aos-duration="2000"
                >
                    <template v-if="item.ulid!='01FY46MX2EW85PP30KQ6S9BXG3'">
                        <picture v-if="item.image">
                            <source class="img-services" :srcset="item.image.content.webpImage" type="image/webp">
                            <img class="img-services" :src="item.image.content.originalImage" alt="image catégorie">
                        </picture>
                        
                        <img class="img-services" v-else src="@/assets/images/import/cosmetique.png" alt="">

                        <div class="top"><h2 class="px-2">{{item.category}}</h2> </div>
                        <div class="bottom px-5"> <p class="limitedText">{{item.description}}</p> </div>
                    </template>
                </div>

                <b-modal id="reservation" scrollable v-if="selectedCategorie" hide-footer size="lg">
                    <h1 class="text-center">{{selectedCategorie.category.category}}</h1>
                    <hr>
                    <p class="text-center text-muted">{{selectedCategorie.category.description}}</p>
                    <div class="row">
                        <div class="col-md-12 col-sm-12" v-for="item in selectedCategorie.tags" :key="item.id" >
                            <h3>{{item.name}}</h3>
                            <hr>
                            <!-- écran large -->
                            <div class="mt-3 background-services-mobile" v-for="item in item.services" :key="item.id">
                                <div class="row" v-if="item.isactive">
                                    <div class="col-md-8 col-sm-12" style="min-width:15rem">
                                        <h5>{{item.name}}</h5>
                                        <p class="">{{item.description}}</p>
                                    </div>
                                    
                                    <!-- écran large -->
                                    <div class="col-md-1 col-sm-4 mt-sm-3 d-flex justify-content-center text-muted computer">
                                       {{calculTimes(item.duration)}} 
                                    </div>
                                    <div class="col-md-1 col-sm-4 mt-sm-3 d-flex justify-content-center computer">{{item.price}}€</div>
                                    <div class="col-md-2 col-sm-4 d-flex justify-content-center computer">
                                        <button class="btn btn-social-icon btn-outline-primary" @click="addToCart(item)"><i class="mdi mdi-calendar-multiple-check"></i></button>
                                    </div>
                                    <!-- mobile -->
                                    <div class="col-sm-12 mobile d-flex justify-content-between">
                                        <span class="mt-3">
                                            {{calculTimes(item.duration)}}
                                        </span>
                                        <span class="mt-3">
                                            {{item.price}}€
                                        </span>
                                        <button class="btn btn-social-icon btn-outline-primary" @click="addToCart(item)"><i class="mdi mdi-calendar-multiple-check"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="d-flex justify-content-center mt-5">
                        <button class="btn btn-gradient-primary" @click="reserve()">Voir la réservation</button>
                    </div> -->
                    <div class="d-flex justify-content-center mt-4">
                        <img class="img-60" src="@/assets/images/import/hornement.png" alt="">
                    </div>
                </b-modal>
            </div>
        </div> <!-- /content-wrapper-->
        <!-- promotions -->
        <div class="content-wrapper">
            <div class="row mt-5 ">
                <div class="col-md-6 col-sm-12 mt-2">
                    <h2>Nos produits</h2>
                </div>
                <div class="col-md-6 col-sm-12 d-flex justify-content-md-end mt-2">
                    <button class="btn btn-outline-primary btn-minimalist">Tous nos produits >></button>
                </div>
            </div>
        </div>
        <!-- liste des promotions -->
        <div class="background-green" data-aos="fade-up" data-aos-duration="3000">
            <div class="content-wrapper no-background">
                <div class="row">
                    <div class="col-md-12">
                            <gold-card
                            scrollcards
                            title="name"
                            description="description"
                            price="price"
                            entitytype="products"
                            />
                    </div>
                </div>
            </div>
        </div>

        <floatReservation />
    </section>
</template>
<script>
import goldCard from '../components/element/gold-card.vue'
// import products from '../json/products'
import axios from 'axios'
import floatReservation from '../components/float-reservation.vue'

export default {
    name: 'services',
    components : {
        goldCard,
        floatReservation
    },
    data () {
        return {
            products:null,
            categorie:null,
            selectedCategorie:null,
            items: [0,1,2,3,4,5,6,7,8]
        }
    },
    methods: {
        getCategories(){
            let url = "categories"
            axios.get(url)
                .then(
                    resgetcategories => {
                        let categorie = resgetcategories.data.result
                        this.categorie=categorie.filter(cat => cat.ulid!=="01FY46MX2EW85PP30KQ6S9BXG3")
                    }
                )
                .catch(
                    errgetProducts => {
                        console.log(errgetProducts)
                    }
                )
        },
        setCategorie(categorie){
            //selected category data model
            let mycategorie = {
                category:categorie,
                tags:[]
            }
            //foreach services element using forEach
            categorie.services.forEach(Serviceselement => {
                //if Serviceselement.tags is not null 
                if (Serviceselement.tags) {
                //get the tagsElement using forEach
                    Serviceselement.tags.forEach(TagsElement => {
                        //create the tags datastructure in first instance 
                        if (mycategorie.tags.length == 0) {
                            let body = {
                                    name: TagsElement,
                                    services: [Serviceselement]
                                }
                            mycategorie.tags.push(body)
                        }else
                        {
                            //verify if mycategorie.tags includes TagsElement
                            for (let i = 0; i < mycategorie.tags.length; i++) {
                                if (mycategorie.tags[i].name == TagsElement ) {
                                    mycategorie.tags[i].services.push(Serviceselement)
                                    i = Number(mycategorie.tags.length) + 1
                                }
                                if (i == (mycategorie.tags.length- 1)  && mycategorie.tags[i].name != TagsElement) {
                                    let body = {
                                        name: TagsElement,
                                        services: [Serviceselement]
                                    }
                                    mycategorie.tags.push(body)
                                    i = Number(mycategorie.tags.length) + 1
                                }
                            }
                        }
                        
                    });
                }
            });
            this.selectedCategorie = mycategorie
        },
        addToCart(item){
            this.$store.dispatch('add_service', item)
            this.$snotify.success("Rendez-vous ajouté", {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                });
            // this.$bvModal.hide('reservation')
        },
        reserve () {
            this.$router.push( {
                name: 'reservation'
            })
        },
        setservices(){
            this.$router.push({
                name:'reservation'
            })
        },
        calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            let heure = Number(timeString.substr(0,2))
            let min = Number(timeString.substr(3,5))
            let stringHeure = ''
            if (heure) {
                stringHeure = heure+ ' h'
            }
            if (min) {
                stringHeure += min + 'mn'
            }
            return stringHeure
        }
    },
    mounted(){
        this.getCategories()
    }
}
</script>

<style scoped>
.gold-circle {
    position: relative;
    min-height: 30rem;
}
 #spinning-ball {
     position: relative;
     width: 30rem;
     bottom: 5rem;
     animation: spinning-ball 4s;
 }
/* animation de la boule */
@keyframes spinning-ball{
    0% {
        left: 60rem;
        transform: rotate(180deg);
        opacity: 0;
    }
    100% {
        left:0rem;
        transform: rotate(0deg);
        opacity: 1;
    }
}

.img-services {
    width: 100%;
    height: 26rem !important;
    object-fit:cover;
    filter: grayscale(1) brightness(40%);
    clip-path: start;
    transition: clip-path 1s;
}
@media screen and (max-width: 756px) {
    .img-services {
        min-width: 34rem
    }
}


.img-container {
    position: relative;
    text-align: center;
}
.top {
  position: absolute;
  top: 4rem;
}
.bottom {
  position: absolute;
  bottom: 8px;
}
.bottom p:hover{
    text-shadow: 1px 1px 2px #fff;
}
.limitedText{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
-webkit-box-orient: vertical;
}

img {
    clip-path: start;
}
.img-container img{
    transition: all 0.4s;
}
.img-container:hover img{
    filter: blur(4px);
    transform: scale(1.01);
    cursor: pointer;
    color: white;
}

/* affichage des prestations/services */
.black-hover{
    color: #fff
}
.black-hover:hover {
    color: black !important;
}


div.modal .modal-dialog.modal-400 {
  width: 400px !important
}

::v-deep .modal-body {
    scrollbar-width: thin;
}
::v-deep .modal, .modal-body {
    scrollbar-color: #565656 #00f0;
    scrollbar-width: thin;
}
.img-60 {
    max-width: 60% !important;
}

@media screen and (max-width: 992px){
    .background-services-mobile:nth-of-type(2n+1) .row{
        background: #f6f6f6;
        padding:1rem 0px
    }
    #reservation h1{
        font-size: 4rem;
    }
}
</style>